























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Document } from '@/models/document.model';

@Component({
  components: {},
})
export default class JnNewsMicro extends Vue {
  @Prop({ required: true })
  doc!: Document;

  goToNews() {
    this.$router.push('/docs/juranews/' + this.doc.id);
  }
}
