


























import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class JnWhatsApp extends Vue {
  attendants = [
      {
        app: 'whatsapp',
        label: 'Contatta il',
        name: 'Servizio Clienti',
        number: '3297570641',
        avatar: {
          src: '/img/logo-whatsapp.png',
          alt: 'Servizio Clienti'
        }
      },
    ]

}
