





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Document } from '@/models/document.model';

@Component({
  components: {},
})
export default class JnServiceCard extends Vue {
  @Prop({ required: true, default: '' })
  service!: Document;
}
